import alert from 'o365.controls.alert.ts';
import O365TokenHandler from 'o365.modules.authentication.O365TokenHandler.ts';

namespace API {
    type Method = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';
    type ResponseStatusHandler = (response: Response, options: IRequestOptions) => Promise<Response>;

    export enum ResponseHandler {
        JSON = 'JSON',
        HTML = 'HTML',
        Text = 'TEXT',
        Stream = 'STREAM',
        Raw = 'RAW'
    }

    interface IRequestOptions {
        requestInfo: RequestInfo,
        method?: Method,
        headers?: Headers | { [key: string]: string },
        body?: BodyInit,
        mode?: RequestMode,
        credentials?: RequestCredentials,
        cache?: RequestCache,
        redirect?: RequestRedirect,
        integrity?: string,
        keepalive?: boolean,
        abortSignal?: AbortSignal,
        responseStatusHandler?: Boolean | ResponseStatusHandler,
        responseBodyHandler?: Boolean | ResponseHandler,
        showErrorDialog?: Boolean,
        skipJwtCheck?: Boolean
    }

    export async function requestPost(url: string, body?: BodyInit, abortSignal?: AbortSignal, credentials: RequestCredentials = 'same-origin'): Promise<any> {
        var h = new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-NT-API': 'true'
            });

        if (body?.constructor === FormData) {
            h.delete('Content-Type');
        }

        return await request({
            requestInfo: url,
            method: 'POST',
            headers:h,
            credentials: credentials,
            body: body,
            abortSignal: abortSignal
        });
    }

    export async function requestGet(url: string, pOptions?: Partial<IRequestOptions>): Promise<any> {
        return await request({
            requestInfo: url,
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            showErrorDialog: pOptions?.showErrorDialog
        });
    }

    export async function requestHtml(url: string, body?: BodyInit, method: Method = 'GET'): Promise<Document> {
        return await request({
            requestInfo: url,
            method: method,
            headers: new Headers({
                'Accept': 'text/html',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            body: body,
            responseBodyHandler: ResponseHandler.HTML
        });
    }

    export async function requestText(url: string, body?: BodyInit, method: Method = 'GET'): Promise<string> {
        return await request({
            requestInfo: url,
            method: method,
            headers: new Headers({
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            body: body,
            responseBodyHandler: ResponseHandler.Text
        });
    }

    export async function requestStream(url: string, body?: BodyInit, method: Method = 'POST', credentials: RequestCredentials = 'same-origin'): Promise<ReadableStreamDefaultReader<Uint8Array>> {
        return await request({
            requestInfo: url,
            method: method,
            headers: new Headers({
                'Accept': 'application/stream+json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            credentials: credentials,
            body: body,
            responseBodyHandler: ResponseHandler.Stream
        });
    }

    export async function requestFile(url:string,body:any){
        return fetch(url,{
            method:body?"POST":"GET",
            body:body?JSON.stringify(body):null,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                'X-NT-API': 'true'
            }
        }).then(async response =>{
             if (response.status === 200) {
                return await response.blob();
            } else {
                throw Error(await response.text());
            }
        })
    }

    export async function requestFileCustomHeaders(url:string,body:any, headers:any){
        return fetch(url,{
            method:body?"POST":"GET",
            body:body?body:null,
            headers: headers
        }).then(async response =>{
             if (response.status === 200) {
                return await response.blob();
            } else {
                throw Error(await response.text());
            }
        })
    }


    export async function request(options: IRequestOptions): Promise<any> {
        try {
            const headers = options.headers;

            if (headers == undefined) {
                options.headers = new Headers({'X-NT-API': 'true'});
            } else if (headers instanceof Headers) {
                if (headers.has('X-NT-API') === false) {
                    headers.append('X-NT-API', 'true')
                } else if (headers.get('X-NT-API') === 'false') {
                    headers.delete('X-NT-API');
                }
            } else if (headers.hasOwnProperty('X-NT-API') === false) {
                headers['X-NT-API'] = 'true';
            } else if (headers['X-NT-API'] === 'false') {
                delete headers['X-NT-API'];
            }

           // options.headers.set('Bear')

            const request = await _generateRequest(options);

            var response: Response | null = await fetch(request);

            const responseStatusHandler = options.responseStatusHandler ?? true;

            if (typeof responseStatusHandler === 'boolean' && responseStatusHandler === true) {
                response = await _defaultResponseStatusHandler(response, options);
            } else if (typeof responseStatusHandler === 'function') {
                response = await responseStatusHandler(response, options);
            }

            if (response?.headers.has('X-Deprecated')) {
                console.warn(response.headers.get('X-Deprecated'));
            }

            if (response === null || options.responseBodyHandler === false || response.status === 204) {
                return response;
            }

            let data: any;

            switch (options.responseBodyHandler ?? ResponseHandler.JSON) {
                case ResponseHandler.JSON:
                    data = await _defaultResponseJsonBodyHandler(response);
                    break;
                case ResponseHandler.HTML:
                    data = await _defaultResponseHtmlBodyHandler(response);
                    break;
                case ResponseHandler.Text:
                    data = await _defaultResponseTextBodyHandler(response);
                    break;
                case ResponseHandler.Stream:
                    data = await _defaultResponseStreamBodyHandler(response);
                    break;
                case ResponseHandler.Raw:
                    data = response;
                    break;
            }

            return data;
        } catch (error: any) {
            if (options.showErrorDialog ?? true) {
                _apiAlert(error, true);
                // alert(error);
            }

            throw error;
        }
    }

    // ---- Generate Request based on options ---- //

    // Request
    async function _generateRequest(options: IRequestOptions): Promise<Request> {
        const requestVerificationTokenInputs = document.querySelectorAll('input[name=__RequestVerificationToken]');

        let requestHasHandler = false;
        
        if (options.requestInfo instanceof Request) {
            requestHasHandler = !!new URL(options.requestInfo.url, window.location.toString()).searchParams.get('handler');

            const requestVerificationTokenInput = requestVerificationTokenInputs[0] as HTMLInputElement;
            const requestVerificationToken = requestVerificationTokenInput.value;

            options.requestInfo.headers.append('RequestVerificationToken', requestVerificationToken);

            return options.requestInfo;
        }

        requestHasHandler = !!new URL(options.requestInfo, window.location.toString()).searchParams.get('handler');

        if (requestVerificationTokenInputs.length === 1 && requestHasHandler) {
            const requestVerificationTokenInput = requestVerificationTokenInputs[0] as HTMLInputElement;
            const requestVerificationToken = requestVerificationTokenInput.value;

            const headers = options.headers ?? new Headers();

            if (headers instanceof Headers) {
                headers.append('RequestVerificationToken', requestVerificationToken);
            } else if (typeof headers === 'object') {
                headers['RequestVerificationToken'] = requestVerificationToken;
            }

            options.headers = headers;
        }

        if (!(options.skipJwtCheck ?? false)) {
            const jwt = await O365TokenHandler.getToken();

            if (jwt !== null) {
                const headers = options.headers ?? new Headers();

                if (headers instanceof Headers && !headers.has('Authorization')) {
                    headers.set('Authorization', `Bearer ${jwt.access_token}`);
                } else if (typeof headers === 'object' && !(headers as {[key: string]: string;}).hasOwnProperty('Authorization')) {
                    (headers as {[key: string]: string;})['Authorization'] = `Bearer ${jwt.access_token}`;
                }

                options.headers = headers;
            }
        }

        const request = new Request(options.requestInfo,
            {
                method: options.method,
                headers: options.headers,
                body: _generateRequestBody(options),
                mode: options.mode,
                credentials: options.credentials,
                cache: options.cache,
                redirect: options.redirect,
                integrity: options.integrity,
                keepalive: options.keepalive,
                signal: options.abortSignal
            });

        return request;
    }

    // Request body
    function _generateRequestBody(options: IRequestOptions): BodyInit | null {
        if ([undefined, null, 'GET', 'DELETE', 'OPTIONS', 'HEAD', 'TRACE', 'CONNECT'].includes(options.method)) {
            return null;
        }

        if (options.body && options.body.constructor === FormData) {
            return options.body;
        }

        const body = options.body;

        if (typeof body === 'object') {
            try {
                return JSON.stringify(body);
            } catch (error) {
                console.warn('Failed to parse body');
            }
        }

        return body ?? null;
    }

    // ---- Response status code verification ---- //

    async function _defaultResponseStatusHandler(response: Response, options: IRequestOptions): Promise<Response | null> {
        if (response.redirected) {
            window.location.href = response.url;
            return null;
        }

        switch (response.status) {
            case 401: // Unauthorized
                var location = response.headers.get('location');
                if(!location) location = '/login';
                var result = await _defaultUnauthorizedHandler(options, location);
                return result ? await _defaultResponseStatusHandler(result, options) : null;
            case 409: // Conflict
                await _defaultConflictHandler(response);
                break;
            case 500: // Internal Server Error
                await _defaultInternalServerError(response);
                break;
            case 502: // Bad Gateway
            case 503: // Service Unavailable
            case 504: // Gateway Timeout
                _defaultServiceUnavailableHandler(response.status);
                break;
        }

        return response;
    } 

    function _isBehindProxy() : boolean{
        return (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content === 'true';
    }

    /**
     * 401 - Unauthorized  
     * Initiates the login dialog and afterwards returns a response for a fetch request from the given options
     */
    async function _defaultUnauthorizedHandler(options: IRequestOptions, url:string): Promise<Response | null> {
        if(_isBehindProxy()){     
            var isMfa = url === '/login/mfa';
            url = '/login?ReturnUrl=%2fapi/sessionexpired/remove';
            if(isMfa){
                url += '&RequireTwoFactor=1'
            }
        }

        var loginNode = document.getElementById('loginFrame');
        if(loginNode === null){
            loginNode = document.createElement('iframe');
            loginNode.id = 'loginFrame';
            loginNode.src = url;
            loginNode.classList.add('session-expired-container');
            loginNode.style.zIndex = '2000';
            loginNode.style.width = '100%';
            loginNode.style.height = '100%';
            loginNode.style.position = 'fixed';
            loginNode.style.top = '0';
            document.body.append(loginNode);
        }        

        await observeAuthenticationDialog(loginNode.id);
        
        const request = await _generateRequest(options);
        var response: Response | null = await fetch(request);

        return response;
    }

    function observeAuthenticationDialog(elementId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const targetNode = document.getElementById(elementId);

            if (!targetNode) {
                console.log('target node not found');
                reject(new Error('Element not found'));
                return;
            }

            const config = { childList: true, subtree: true };

            const callback = function (mutationsList:any, observer:MutationObserver) {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childRemoved') {
                        console.log('auth observer stopped2');
                        observer.disconnect();
                        resolve();
                        return;
                    }
                    if (mutation.type === 'childList') {
                        const removedNodes = mutation.removedNodes;
                        for (const removedNode of removedNodes) {
                            if (removedNode.id === elementId) {
                                console.log('auth observer stopped');
                                observer.disconnect();
                                resolve();
                                return;
                            }
                        }
                    }
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(document.querySelector("body")!, config);
            console.log('auth observer started');
        });
    }

    // 409 - Conflict
    async function _defaultConflictHandler(response: Response): Promise<void> {
        const jsonResponse = await response.json();

        throw ({
            error: jsonResponse.error,
            status: 409
        })
    }

    async function _defaultInternalServerError(response: Response): Promise<void> {
        const jsonErrorMessage = await (async () => {
            try {
                const jsonResponse = response.clone();

                const jsonResponseBody = await jsonResponse.json();

                if (jsonResponseBody.error) {
                    return jsonResponseBody.error;
                }
            } catch (reason) {}

            return null;
        })();

        if (jsonErrorMessage) {
            throw jsonErrorMessage;
        }

        const textErrorMessage = await (async () => {
            try {
                const textResponse = response.clone();

                const textResponseBody = await textResponse.text();

                return textResponseBody;
            } catch (reason) {}

            return null;
        })();

        if (textErrorMessage) {
            throw textErrorMessage;
        }
    }

    // 502 - Bad Gateway
    // 503 - Service Unavailable
    // 504 - Gateway Timeout
    function _defaultServiceUnavailableHandler(statusCode: number) {
        _apiAlert('Could not reach server. Please try again later');

        throw ({
            error: "Could not reach server. Please try again later",
            status: statusCode
        });
    }

    // ---- Response body parsing ---- //

    // JSON
    async function _defaultResponseJsonBodyHandler(response: Response): Promise<any> {
        if (response.status !== 200) {
            throw response;
        }

        const responseBody = await response.json();

        if(!responseBody) return responseBody;

        if (responseBody.error) {
            throw responseBody.error;
        }

        if (responseBody.success) {
            if (responseBody.success.Result) {
                return responseBody.success.Result;
            } else {
                return responseBody.success;
            }
        } else {
            return responseBody;
        }
  
      
    }

    // HTML
    async function _defaultResponseHtmlBodyHandler(response: Response): Promise<Document> {
        if (response.status !== 200) {
            throw response;
        }

        const responseBody = await response.text();
        const domParser = new DOMParser();
        const html = domParser.parseFromString(responseBody, 'text/html');

        return html;
    }

    // Text
    async function _defaultResponseTextBodyHandler(response: Response): Promise<string> {
        if (response.status !== 200) {
            throw response;
        }

        const responseBody = await response.text();

        return responseBody;
    }

    // Stream
    async function _defaultResponseStreamBodyHandler(response: Response): Promise<ReadableStreamDefaultReader<Uint8Array> | null> {
        if (response.status !== 200) {
            throw response;
        }

        const responseBody = response.body?.getReader();

        return responseBody ?? null;
    }

    // ---- Helpers ---- //

    /**
     * Attempt to show an alert, only 1 alert will be shown at a time
     */
    async function _apiAlert(message: string | Response, pSkipAlertShowCheck = false) {
        try {
            if (_apiAlertIsShown && !pSkipAlertShowCheck) { return; }
            _apiAlertIsShown = true;
            const alertModule = await import('o365.controls.alert.ts');
            if (message instanceof Response) {
                message = await message.json();
            }
            alertModule.default(message);
            window.setTimeout(() => {
                _apiAlertIsShown = false;
            }, 100);
        } catch (_ex) {
            console.error(message);
        }
    }
}

let _apiAlertIsShown = false;

export default API;
